@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/corner-radius';
@use '@sats-group/ui-lib/tokens/light';

.edit-boot-camp-participants {
  &__header-wrapper {
    background: light.$background-secondary-default;
    padding-bottom: spacing.$l;
    padding-top: spacing.$m;
  }

  &__header-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: spacing.$xxs;
  }

  &__header-bottom {
    display: flex;
    flex-direction: column;
    gap: spacing.$s;
  }

  &__description, &__no-participants-description {
    color: light.$on-surface-primary-alternate;
  }

  &__content {
    padding: spacing.$s 0 0 spacing.$m;
    margin: spacing.$m 0;
    border-radius: corner-radius.$m;
    background-color: light.$background-secondary-default;
  }

  &__content-header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: spacing.$s;
    padding-right: spacing.$s;
  }

  &__content-header {
    display: flex;
    gap: spacing.$xs;
  }

  &__participants {
    margin: 0;
    padding: 0;
    text-decoration: none;
  }

  &__no-participants {
    display: flex;
    gap: spacing.$s;
    margin-top: spacing.$s;
    padding-bottom: spacing.$s;
  }
  &__no-participants-icon {
    flex-shrink: 0;
  }

  &__no-participants-add {
    display: flex;
    margin-top: spacing.$s;
  }

  &__remove-all-participants {
    background: light.$surface-primary-default;
    border-radius: corner-radius.$s;
    margin-top: spacing.$m;
  }
}
